<template>
  <tr v-if="editTreat !== {}">
    <v-snackbar
      :timeout="3000"
      :color="snackbarStatus"
      class="text-col"
      rounded="pill"
      v-model="showSnackbar"
      location="top"
    >
      {{ snackbarText }}
    </v-snackbar>
    <td class="reorder">
      <div
        class="d-flex flex-row flex-wrap flex-md-nowrap gap-2 align-center justify-center"
      >
        <VIcon icon="mdi-reorder-horizontal" size="large"></VIcon>
        <VSelect
          style="min-width: 90px; max-width: 90px; text-align: center"
          variant="solo"
          density="compact"
          v-model="currentIndex"
          :items="Array.from({ length: totalTreats }, (_, i) => i + 1)"
          @update:modelValue="updateItemsOrder"
        >
        </VSelect>
      </div>
    </td>
    <td class="drink_image">
      <div>
        <div
          class="d-flex flex-row flex-wrap align-center py-2 justify-center gap-3"
          :class="{
            'custom-treat-image': isAdmin && editTreat.type === 'CUSTOM',
          }"
        >
          <n-image
            width="50"
            :src="editTreat.imageUrl"
            v-if="editTreat.imageUrl"
          />
          <UploadDrinkImage
            v-if="isAdmin && editTreat.type === 'CUSTOM'"
            :id="editTreat.id"
            v-on:drinkImageUploaded="imageUploaded"
          />
        </div>
        <div
          v-if="!isAdmin && !editTreat.imageUrl"
          class="font-weight-bold text-teal"
          style="opacity: 0.3"
        >
          Under review by Dion
        </div>
      </div>
    </td>
    <td>
      <div
        v-show="!nameEditable"
        @click="nameEditable = true"
        style="cursor: pointer; min-width: 10rem"
      >
        {{ editTreat.name }}
      </div>
      <n-input
        v-show="nameEditable"
        v-model:value="editTreat.name"
        @update:value="handleNameChange"
        @keydown="nameKeyDown"
      />
    </td>
    <td v-if="editTreat.price">
      <div v-if="!priceEditable" style="min-width: 100px">
        <PriceFormatter
          :amount="multiplyPriceByCurrency(editTreat.price.amount, currency)"
          :currency="currency"
        />
        <v-btn
          color="secondary"
          variant="text"
          density="compact"
          icon="mdi-pencil"
          @click="priceEditable = true"
        ></v-btn>
      </div>
      <v-text-field
        v-if="priceEditable"
        type="number"
        :suffix="Currencies[currency]"
        placeholder="Enter treat price"
        style="min-width: 10rem"
        :append-inner-icon="priceHasChanged ? 'mdi-check' : ''"
        v-model="editTreat.price.amount"
        @keydown="priceKeyDown"
        :class="{
          'green-checkmark': priceHasChanged,
        }"
        @click:append-inner="updateDrinksPrice"
      ></v-text-field>
    </td>
    <td>
      <v-select
        v-if="editTreat.type === 'CUSTOM'"
        style="min-width: 120px"
        clearable
        density="compact"
        label="Tag"
        :items="Object.values(TreatTag)"
        v-model="editTreat.tag"
        @update:model-value="updateDrinksTag"
      >
        <template v-slot:selection="{ item, index }">
          <span style="font-size: 12px; text-align: start">{{
            TreatTagToName[item.value as TreatTag]
          }}</span>
        </template>
      </v-select>
    </td>
    <td v-if="editTreat.price" style="width: 50px">
      <n-checkbox
        :disabled="!editTreat.imageUrl || !editTreat.price.amount"
        v-model:checked="editTreat.enabled"
        @update:checked="updateDrinksAvailability"
      />
    </td>
    <td v-if="editTreat.id" style="width: 50px">
      <DeleteCustomTreat :id="editTreat.id" />
    </td>
  </tr>
</template>

<style scoped>
td {
  text-align: center;
}
.reorder {
  cursor: grab;
}
.reorder:deep(.v-field__input) {
  justify-content: center;
}
.green-checkmark:deep(.v-icon) {
  color: green;
}
.custom-treat-image {
  margin-right: -45px;
}
@media (max-width: 1000px) {
  .custom-treat-image {
    margin-right: 0;
  }
}
</style>

<script setup lang="ts">
import { NCheckbox, NImage, NInput } from "naive-ui";
import type { PropType } from "vue";
import { computed, onMounted, ref } from "vue";
import {
  type CustomTreatFromResponse,
  TreatTag,
  TreatTagToName,
} from "@/views/menus/drinks/types/drinks";
import { Currencies } from "@/types/Venue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";
import { KeyType } from "@/types/System";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";
import DeleteCustomTreat from "@/views/menus/drinks/components/DeleteCustomTreat.vue";
import UploadDrinkImage from "@/views/menus/drinks/components/UploadDrinkImage.vue";
import { cache, cachedKeys } from "@/utils/cache";
import { VenueUserRole } from "@/views/waiters/types/VenueUser";
import PriceFormatter from "@/components/PriceFormatter.vue";
import {
  dividePriceByCurrency,
  multiplyPriceByCurrency,
} from "@/common/Amount";

const props = defineProps({
  treat: {
    type: Object as PropType<CustomTreatFromResponse>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  totalTreats: {
    type: Number,
    required: true,
  },
});
const emits = defineEmits(["orderUpdated"]);
const drinkStore = useDrinkStore();

const showSnackbar = ref(false);
const snackbarText = ref("");
const snackbarStatus = ref("warning" as "warning" | "error");
const editTreat = ref({} as CustomTreatFromResponse);
const drinkService = new DrinkService();
const nameEditable = ref(false);
const priceEditable = ref(false);
const venueUserRole = cache.getCache(cachedKeys.ROLE);
const isAdmin = ref(venueUserRole == VenueUserRole.ADMIN);
const currency = ref("");
const currentIndex = ref(0);
const priceHasChanged = computed(
  () =>
    props.treat?.price.amount !==
    multiplyPriceByCurrency(editTreat.value.price.amount, currency.value)
);
let timeout = undefined as number | undefined;

const updateItemsOrder = () => {
  clearTimeout(timeout);

  timeout = setTimeout(async () => {
    emits("orderUpdated", {
      previousIndex: props.index,
      newIndex: currentIndex.value - 1,
    });
  }, 1000);
};
function nameKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case KeyType.ESCAPE:
      restoreDefaultNameValue();
      nameEditable.value = false;
      break;
    case KeyType.ENTER:
    case KeyType.TAB:
      nameEditable.value = false;
      break;
  }
}

function priceKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case KeyType.ESCAPE:
      restoreDefaultPriceValue();
      priceEditable.value = false;
      break;
    case KeyType.ENTER:
      updateDrinksPrice();
      break;
  }
}

const restoreDefaultNameValue = () => {
  if (props.treat?.name) editTreat.value.name = props.treat.name;
};
const restoreDefaultPriceValue = () => {
  if (props.treat) setEditDrink(props.treat);
};

const handleNameChange = async () => {
  clearTimeout(timeout);

  timeout = setTimeout(async () => {
    if (!editTreat.value.name) {
      snackbarStatus.value = "warning";
      snackbarText.value = "Treat name can't be empty";
      showSnackbar.value = true;
      restoreDefaultNameValue();
      return;
    }
    await updateDrinksName();
  }, 1000);
};

const handleTagChange = async () => {};

const updateDrinksName = async () => {
  if (!editTreat.value.name) return;

  const updatedDrink = await drinkService.updateTreat(editTreat.value.id, {
    name: editTreat.value.name,
  });

  if (!updatedDrink) {
    snackbarStatus.value = "error";
    snackbarText.value = "Something went wrong updating treat's data";
    showSnackbar.value = true;
    return;
  }

  await drinkStore.fetchVenueDrinks();
};

const updateDrinksPrice = async () => {
  const price = editTreat.value.price.amount;
  const objectToSent = {
    enabled: !price ? false : undefined,
    price: {
      amount: price ? multiplyPriceByCurrency(price, currency.value) : null,
      currency: currency.value,
    },
  };
  const updatedDrink = await drinkService.updateTreat(
    editTreat.value.id,
    objectToSent
  );

  if (!updatedDrink) {
    snackbarStatus.value = "error";
    snackbarText.value = "Something went wrong updating treat's data";
    showSnackbar.value = true;
    return;
  }

  await drinkStore.fetchVenueDrinks();
  priceEditable.value = false;
};

const updateDrinksAvailability = async (value: boolean) => {
  const objectToSent = {
    enabled: value,
  };
  const updatedDrink = await drinkService.updateTreat(
    editTreat.value.id,
    objectToSent
  );

  if (!updatedDrink) {
    snackbarStatus.value = "error";
    snackbarText.value = "Something went wrong updating treat's data";
    showSnackbar.value = true;
    return;
  }

  await drinkStore.fetchVenueDrinks();
};

const updateDrinksTag = async (tag: TreatTag | null) => {
  editTreat.value.tag = tag;
  const objectToSent = {
    tag,
  };
  const updatedDrink = await drinkService.updateTreat(
    editTreat.value.id,
    objectToSent
  );

  if (!updatedDrink) {
    snackbarStatus.value = "error";
    snackbarText.value = "Something went wrong updating treat's data";
    showSnackbar.value = true;
    return;
  }

  await drinkStore.fetchVenueDrinks();
};
const imageUploaded = async () => {
  await drinkStore.fetchVenueDrinks();
  setEditDrink(props.treat!);
};

const setEditDrink = (treat: CustomTreatFromResponse) => {
  if (treat == ({} as CustomTreatFromResponse)) return;
  editTreat.value = {
    enabled: treat.enabled,
    id: treat.id,
    name: treat.name,
    imageUrl: treat.imageUrl,
    price: {
      amount: dividePriceByCurrency(treat.price?.amount, treat.price.currency),
      currency: treat.price.currency,
    },
    type: treat.type,
    tag: treat.tag,
  };
};

onMounted(() => {
  if (props?.treat && props.treat !== ({} as CustomTreatFromResponse)) {
    setEditDrink(props.treat);
    currency.value = editTreat.value.price.currency;
  }
  currentIndex.value = props.index + 1;
});
</script>
