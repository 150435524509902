<template>
  <tr>
    <th style="width: 15%"><div class="header"></div></th>
    <th style="width: 15%"><div class="header">Image</div></th>
    <th style="width: 30%" class="header">
      <div class="header">Display name</div>
    </th>
    <th style="width: 15%" class="header">
      <div class="header">Price</div>
    </th>
    <th style="width: 15%" class="header">
      <div class="header">Tag</div>
    </th>
    <th style="width: 2%"><div class="header">Availability</div></th>
    <th style="width: 2%"><div class="header"></div></th>
  </tr>
</template>

<style scoped>
.header {
  font-weight: bold;
  text-align: center;
}
</style>

<script setup lang="ts"></script>
