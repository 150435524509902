import type { Amount } from "@/common/Amount";

export interface BrowseDrinkResponse {
  name: string;
  price: Amount;
  type: string;
  imageUrl: string | null;
}
export interface GetAvailableDrinksResponse {
  drinks: DrinkFromResponse[];
  optionGroups: Record<string, Option[]>;
  customTreats: CustomTreatFromResponse[];
}

export interface DrinkFromResponse {
  id: string;
  name: string;
  originalName: string;
  imageUrl: string;
  price: { amount: number | null; currency: string };
  originalPrice: { amount: number | null; currency: string };
  enabled: boolean;
  menuItem: MenuItem | null | undefined;
  categories: string[];
  options?: string[];
  sizes: Size[];
}

export interface CustomTreatFromResponse {
  id: string;
  name: string;
  type: string;
  price: Amount;
  imageUrl: string | null;
  enabled: boolean;
  tag: TreatTag | null;
}

export enum TreatTag {
  NON_ALCOHOLIC = "NON_ALCOHOLIC",
}

export const TreatTagToName = {
  [TreatTag.NON_ALCOHOLIC]: "Non alcoholic",
};
export interface Size {
  quantity: string;
  price: { amount: number | null; currency: string };
  menuItem: { id: string | undefined; name: string | undefined } | null;
}
export interface Extras {
  sizes: { price: number | null; quantity: string }[];
}
export interface Drink {
  id: string;
  name: string;
  originalName: string;
  imageUrl: string;
  price: { amount: number | null; currency: string };
  originalPrice: { amount: number | null; currency: string };
  enabled: boolean;
  sizes: Size[];
  menuItem: MenuItem | null;
}
export interface Option {
  id: string;
  name: string;
  imageUrl: string;
  price: { amount: number | null; currency: string };
  originalPrice: { amount: number | null; currency: string };
  enabled: boolean;
}

export interface SimplifiedOption {
  id: string;
  name: string;
  imageUrl: string;
  price: number | null;
  originalPrice: number | null;
  enabled: boolean;
}

export interface MenuItem {
  id: string | undefined;
  name: string | undefined;
}
